import { Slice, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  delayedToggle,
  // objectHasKey
} from "./../../utils"
import { RootState } from "./../"
import { SELF_APP_NAME } from "../../lib/app-config"

interface AppState {
  name: string
  bools: {
    loading: boolean
    init: boolean
    showSignin: boolean
  }
  theme: {
    key: "dark" | "light"
  }
  route: {
    active: string
  }
  user: {
    user: any
    data: any
    profile: any
  }
}


const STORAGE_KEY = `${SELF_APP_NAME}-app::`

export const getStorageKey = (str: string) => `${STORAGE_KEY}${str}`;

const THEME_ALGO_KEY = getStorageKey("theme-mode");

const initialState: AppState = {
  name: process.env.REACT_APP_WEBSITE_NAME || "",
  bools: {
    init: false,
    loading: true,
    showSignin: true,
  },
  route: {
    active: "home",
  },
  theme: {
    key: localStorage.getItem(THEME_ALGO_KEY) as "dark" | "light" ?? "light",
  },
  user: {
    user: null,
    data: null,
    profile: null,
  }
}

function resetAppState(state: AppState) {
  state.bools.init = false
  // state.bools.showSignin = true;
  state.route.active = "home"
  state.user = {
    user: null,
    data: null,
    profile: null,
  }
  return state
}

export const delayedToggleAppLoading = createAsyncThunk(
  "app/delayedAppLoading",
  async (data: { value: boolean, duration?: number }): Promise<boolean> => {
    // The value we return becomes the `fulfilled` action payload
    const { value, duration = 1000 } = data
    const resp = await delayedToggle(value, duration)
    return resp.data
  }
)

const DELAY_toggleAppTheme = 350;
const DELAY_initializeAppSlice = 750;

export const toggleAppTheme = createAsyncThunk(
  "app/toggleAppTheme",
  async (data: { key: "dark" | "light"}, { dispatch }) => {
    try {
      // const { key } = data;
      dispatch(setAppLoading(true));
      dispatch(setAppTheme(data));
      await dispatch(delayedToggleAppLoading({ value: false, duration: DELAY_toggleAppTheme }));
    } catch (e) {
      const err = e as Error;
      console.warn("error in app/toggleAppTheme =>", err);
    }
  },
)

// Async action creator using Redux Thunk
export const initializeAppSlice = createAsyncThunk(
  'app/initializeApp',
  async (_, { dispatch }) => {
    try {
      // Simulate fetching data asynchronously (replace with your actual data fetching logic)
      const key = localStorage.getItem(THEME_ALGO_KEY) ?? "light";
      // Dispatch the setAppTheme action to set the app theme
      dispatch(setAppInit(true));
      dispatch(setAppLoading(true));
      dispatch(setAppTheme({ key }));

      await dispatch(delayedToggleAppLoading({ value: false, duration: DELAY_initializeAppSlice }));

      // Return any data if needed
      return {
        theme: {
          key,
        }
      };
    } catch (error) {
      // Handle any errors
      console.error('Error initializing app:', error);
      throw error;
    }
  }
);

// const VALID_THEME_ALGOS: ["dark", "light"] = ["dark", "light"];

const appSlice: Slice<AppState> = createSlice({
  name: SELF_APP_NAME,
  initialState,
  reducers: {
    toggleInit: (state: AppState, action: PayloadAction<boolean>) => {
      state.bools.init = action.payload
      // setAppTheme({ key: themeAlgo, });
    },
    toggleLoading: (state: AppState, action: PayloadAction<boolean>) => {
      state.bools.loading = action.payload
    },
    toggleActive: (state: AppState, action: PayloadAction<string>) => {
      state.route.active = action.payload
    },
    toggleShowSignin: (state: AppState, action: PayloadAction<boolean>) => {
      state.bools.showSignin = action.payload
    },
    toggleTheme: (state: AppState, action: PayloadAction<{ key: "dark" | "light" }>) =>{
      if (action.payload) {
        const { key } = action.payload;
        state.theme.key = key;
        localStorage.setItem(THEME_ALGO_KEY, key);
      }
    },
    resetApp: (state: AppState, action: PayloadAction<null>) => {
      resetAppState(state)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(delayedToggleAppLoading.fulfilled, (state: AppState, action: PayloadAction<boolean>) => {
        state.bools.loading = action.payload
      })
      .addCase(initializeAppSlice.fulfilled, (state: AppState, action: PayloadAction<any>) => {
        console.log("[app] [init]");
        // console.log("initializeApp fufilled [state]: =>", {...state.theme});
        // console.log("initializeApp fufilled [payload]: =>", action.payload);
      });
  }
})

export const setAppLoading        = appSlice.actions.toggleLoading
export const setAppInit           = appSlice.actions.toggleInit
export const setAppTheme          = appSlice.actions.toggleTheme
export const setShowSignin        = appSlice.actions.toggleShowSignin
export const setActiveRoute       = appSlice.actions.toggleActive
export const resetApp             = appSlice.actions.resetApp

export const selectAppName        = (state: RootState): string           => state.app.name
export const selectAppLoading     = (state: RootState): boolean          => state.app.bools.loading
export const selectAppInit        = (state: RootState): boolean          => state.app.bools.init
export const selectAppTheme       = (state: RootState): any              => state.app.theme
export const selectAppThemeKey    = (state: RootState): "light" | "dark" => state.app.theme.key;
export const selectShowSignin     = (state: RootState): boolean          => state.app.bools.showSignin
export const selectActiveRoute    = (state: RootState): string           => state.app.route.active

export default appSlice.reducer
