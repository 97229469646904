import { ReactNode, memo, useState } from "react";
import AntdLayout from "antd/lib/layout";
import { theme, Button, ConfigProvider, Switch } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import SiderComp from "./SiderComp";
import { AppThemeProps } from "../utils/app-types";

const { useToken } = theme;
const { Header, Content } = AntdLayout;

export interface LayoutProps extends AppThemeProps {
  children: ReactNode;
}

function Layout({ children, themeConfig, themeAlgorithm, updateThemeAlgorithm }: LayoutProps) {

  const [collapsed, setCollapsed] = useState(true);

  const {
    token: { colorBgContainer, colorBgLayout, borderRadiusLG, colorBgBase, },
  } = useToken();

  return (
    <ConfigProvider theme={themeConfig}>
      <AntdLayout style={{ height: "100dvh", width: "100dvw", alignSelf: "center", }}>
        <SiderComp colorBgLayout={colorBgLayout} collapsed={collapsed} setCollapsed={setCollapsed} themeAlgorithm={themeAlgorithm} borderRadiusLG={borderRadiusLG} />
        <AntdLayout  >
          <Header style={{ background: colorBgBase, padding: 0, }}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                // color: "white",
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
            <Switch
              style={{ marginRight: "auto" }}
              value={themeAlgorithm === "dark"}
              onChange={async (val: boolean) => await updateThemeAlgorithm(val ? "dark" : "light")}
            />
          </Header>
          <Content
            className="hide-scrollbar"
            style={{
              height: "100%",
              width: "100%",
              overflowX: "hidden",
              // padding: 12,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </Content>
        </AntdLayout>
      </AntdLayout >
    </ConfigProvider >
  );
}

export default memo(Layout);
