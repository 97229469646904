
import { Suspense, lazy, memo, useCallback, useState } from "react";
import { ConfigProvider, Spin } from 'antd';
import { ProSkeleton } from '@ant-design/pro-components';
import { QuestionCircleOutlined } from '@ant-design/icons';
import HelpFloatButton from '../components/HelpFloatButton';
import Layout from '../components/Layout';
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectShowSignin, setShowSignin } from "../store/reducers/app";
import { AnimationDefinition, motion } from "framer-motion";
import HomeView from "./HomeView";
import { useOutletContext } from "react-router-dom";
import { AppOutletContext } from "../utils/app-types";

// const DynFirebaseSigninView = lazy(() => import("./FirebaseSigninView"));
const DynSigninView = lazy(() => import("./SigninView"));

const MDiv = motion.div;

function RootView() {

  const { themeAlgorithm, themeConfig, updateThemeAlgorithm } = useOutletContext<AppOutletContext>();

  const dispatch = useAppDispatch();
  const storeSigninShow = useAppSelector(selectShowSignin);

  const [localShowSignin, setLocalShowSignin] = useState<boolean>(true);

  const toggleSigninVis = useCallback((val: boolean) => {
    dispatch(setShowSignin(val));
  }, [dispatch]);

  return (
    <ConfigProvider theme={themeConfig} locale={{ locale: "en" }}>
      <Layout themeAlgorithm={themeAlgorithm} themeConfig={themeConfig} updateThemeAlgorithm={updateThemeAlgorithm} >
        <Suspense fallback={<Spin style={{}} spinning size={"large"}><ProSkeleton type="list" /></Spin>}>
          {
            localShowSignin ?
              (
                <MDiv
                  style={{ height: "100%", width: "100%", display: localShowSignin ? "flex" : "none" }}
                  initial={{ opacity: localShowSignin ? 0 : 1 }}
                  animate={{ opacity: storeSigninShow ? 1 : 0 }}
                  onAnimationComplete={(e: AnimationDefinition) => {
                    // const animOpacity = (e as any).opacity;
                    if (!storeSigninShow && localShowSignin) {
                      setLocalShowSignin(false);
                    }
                  }}
                  exit={{ opacity: 0 }}
                >
                  <DynSigninView userContinue={toggleSigninVis} />
                  {/* <DynFirebaseSigninView userContinue={toggleSigninVis} /> */}
                </MDiv>
              ) :
              (<HomeView />)
          }
        </Suspense>
        <HelpFloatButton tooltip={"Help"} icon={<QuestionCircleOutlined />} />
      </Layout>
    </ConfigProvider>
  );
}

export default memo(RootView);