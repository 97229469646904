// import { GlobalToken, theme } from "antd";
import TypographyText from "antd/lib/typography/Text";
import TypographyLink from "antd/lib/typography/Link";
import Layout from "antd/lib/layout";
import theme from "antd/lib/theme";
import { SELF_APP_AUTHOR } from "../lib/app-config";
import { GithubOutlined } from "@ant-design/icons";
import { Flex } from "antd";

const { Footer: AntdFooter } = Layout;
const { useToken } = theme;

export interface FooterProps {
  // token: GlobalToken;
}

export default function Footer(_: FooterProps) {
  const { token } = useToken();
  const { colorTextLabel } = token;
  return (
    <>
      <AntdFooter style={{ background: "transparent", textAlign: "center", alignSelf: "center" }}>
        <Flex vertical>
          <TypographyText style={{ paddingBottom: 0 }}>
            {`© 2024 All rights reserved.`}
          </TypographyText>
          <TypographyLink style={{ color: colorTextLabel }} target={"_blank"} href={`https://github.com/${SELF_APP_AUTHOR}`}>
            <GithubOutlined style={{ marginTop: 5, }} />
          </TypographyLink>
        </Flex>

      </AntdFooter >
    </>
  )
}