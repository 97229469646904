import { MouseEvent, memo, useCallback, useState } from "react";
import { AnimationDefinition, motion } from "framer-motion";
import { Card, Col, Flex, Modal, ModalFuncProps, Typography } from "antd";
import { listInSyleVariants, listItemInSyleVariants } from "../lib/motion/list-styles";
import { useBreakpoint } from "@ant-design/pro-components";
import { InfoCircleOutlined } from "@ant-design/icons";
import Footer from "../components/Footer";

export interface HomeViewProps {

}

const { li: Mli, div: Mdiv, ul: Mul } = motion;
const { Title: TypographyTitle, Paragraph: TypographyParagraph } = Typography;

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

interface ModalTitleProps {
  title: string;
  subtitle: string;
}

function ModalTitle({ title, subtitle }: ModalTitleProps) {
  return (
    <Col xs={{ span: 24 }} style={{ textWrap: "wrap", textAlign: "center", }}>
      <TypographyTitle level={3}>{"📚"}{title}{"📚"}</TypographyTitle>
      <TypographyParagraph>{subtitle}</TypographyParagraph>
    </Col>
  );
}

const ModalTitleMemo = memo(ModalTitle);

interface ModalProps extends ModalTitleProps {
  info: string;
  afterClose: () => void;
}

function initModalData({ title, subtitle, info, afterClose }: ModalProps): ModalFuncProps {
  // const { title, subtitle } = data;
  return {
    afterClose,
    title: <ModalTitleMemo title={title} subtitle={subtitle} />,
    content: (
      <Col style={{ alignSelf: "center", textAlign: "center" }}>
        <TypographyParagraph>{info}</TypographyParagraph>
      </Col>
    ),
    // width: "100%",
    centered: true,
  }
}

function HomeView() {
  const breakpoint = useBreakpoint();
  const [modal, modalContextHolder] = Modal.useModal();
  const [showBtnList, setShowBtnList] = useState<"open" | "closed">("open");

  const showModal = useCallback((value: any) => {
    const modalOpts = {
      title: `title ${value}`,
      subtitle: `this is a subtitle for card ${value}`,
      info: `this is the info for card ${value}. this will be the application info for card ${value}`,
      afterClose: () => {
        setShowBtnList("open");
      }
    }
    const modalFuncProps: ModalFuncProps = initModalData(modalOpts);
    modalFuncProps.icon = <InfoCircleOutlined style={{ margin: 0 }} />
    modalFuncProps.closable = true;
    modalFuncProps.okType = undefined;
    modalFuncProps.style = {
      // fontWeight: "bolder"
    }
    modalFuncProps.footer = <Footer />
    modal.info(modalFuncProps);
  }, [modal, setShowBtnList]);

  return (
    <Flex style={{ height: "100%", width: "100%" }} justify={"center"} align={"center"} vertical gap={15}>
      <Mul
        style={{
          padding: 0,
          alignSelf: "center",
          width: breakpoint === "sm" || breakpoint === "xs" ? "100%" : "85%",
          textAlign: "center"
        }}
        animate={showBtnList}
        initial={"closed"}
        variants={listInSyleVariants}
        onAnimationComplete={(e: AnimationDefinition) => {
          console.log("mul anim comp =>", e);
        }}
      >
        {
          colors.map((color: string, i: number) => {
            const steez = { border: `2px solid ${color}` };
            return (
              <Mli
                key={`mli-btn-${i}-item`}
                style={{
                  ...steez,
                  listStyle: "none",
                  margin: 5,
                  cursor: "pointer",
                  userSelect: "none",
                  borderRadius: 5,
                }}
                variants={listItemInSyleVariants}
                whileHover={{ scale: 1.075 }}
                whileTap={{ scale: 0.925 }}
              >
                <Card
                  onClick={(e: MouseEvent<HTMLElement>) => {
                    setShowBtnList("closed");
                    showModal(i);
                  }}
                // style={{
                //   ...steez
                // }}
                >
                  <Card.Meta title={<Mdiv>button {i}</Mdiv>} />
                </Card>
              </Mli>
            )
          })
        }
      </Mul>
      {modalContextHolder}
    </Flex>
  )
}

export default memo(HomeView);
