import { memo, useCallback, useState } from "react";
import AntdQRCode from "antd/lib/qrcode"
import { Avatar, Image, Popover } from "antd";

export interface QRCodeProps {
  collapsed: boolean;
}

type QRStatus = "loading" | "active" | "expired";

function QRCode({ collapsed }: QRCodeProps) {

  const [qrStatus, setQRStatus] = useState<QRStatus>("expired");

  const [showQR, setShowQR] = useState<boolean>(false);

  const updateQRStatus = useCallback((vis: boolean) => {
    if (qrStatus === "loading" && vis) {
      return;
    }

    let newStatus: QRStatus;
    let endStatus: QRStatus;
    switch (qrStatus) {
      case "expired": { // start up or reset
        newStatus = "loading";
        endStatus = "active";
        break;
      }
      case "active": { // already active so user wants to hide, start loading
        newStatus = "loading";
        endStatus = "expired";
        break;
      }
      default: {
        return;
      }
    }

    setQRStatus(newStatus);
    if (newStatus === "loading") {
      setShowQR(true);
    }
    const updateLoadStatus = setTimeout(() => {
      setQRStatus(endStatus);
      if (endStatus === "expired") {
        const hideQR = setTimeout(() => {
          clearTimeout(hideQR);
          setShowQR(false);
        }, 50);
      }
    }, 350);
    return () => {
      clearTimeout(updateLoadStatus);
    }
  }, [qrStatus]);

  // useEffect(() => {
  //   if (!init) {
  //     setInit(true);
  //     if (show) {
  //       updateQRStatus(show);
  //     }
  //   }
  // }, [show, init, updateQRStatus]);

  return (
    <Popover open={showQR && !collapsed} onOpenChange={(vis: boolean) => updateQRStatus(vis)}
      overlayStyle={{ padding: 0 }}
      content={<AntdQRCode status={qrStatus}
        style={{
          alignSelf: "center",
        }}
        type="svg"
        value="https://azapps.web.app/" />}>
      <Avatar
        style={{ marginLeft: 5, marginTop: 10, marginBottom: 10 }}
        size={44}
        icon={<Image preview={false} loading={"lazy"} src={"/100.png"} alt={"logo"} width={44} height={44} />}
      />
    </Popover>);
}

export default memo(QRCode);