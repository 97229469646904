import AntdApp from "antd/lib/app";
import ConfigProvider from "antd/lib/config-provider";
import { SELF_APP_NAME } from "./lib/app-config";
import { Spin, ThemeConfig, theme } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { Outlet } from "react-router-dom";
import { initializeAppSlice, selectAppInit, selectAppLoading, selectAppThemeKey, toggleAppTheme } from "./store/reducers/app";

/** CSS */
import 'antd/dist/reset.css';
import './assets/css/scrollbar.css'

const STORAGE_KEY = `${SELF_APP_NAME}-app::`

const { darkAlgorithm, defaultAlgorithm } = theme;
const defaultConfig: ThemeConfig = {
  algorithm: [darkAlgorithm, defaultAlgorithm],
};

export const getStorageKey = (str: string) => `${STORAGE_KEY}${str}`

function App() {
  const dispatch = useAppDispatch();
  const storeAppInit = useAppSelector(selectAppInit);
  const storeAppLoading = useAppSelector(selectAppLoading);
  const storeAppThemeKey = useAppSelector(selectAppThemeKey);

  const [themeAlgorithm, setThemeAlgorithm] = useState<"light" | "dark">(storeAppThemeKey);// (localStorage.getItem(THEME_ALGO_KEY) as "light" | "dark" | null ?? VALID_THEME_ALGOS[0]);
  const [themeConfig, setThemeConfig] = useState<ThemeConfig>({
    ...defaultConfig,
    algorithm: storeAppThemeKey === "dark" ? darkAlgorithm : defaultAlgorithm,
  });

  const updateThemeConfig = useCallback(async (newAlgo: "light" | "dark") => {
    setThemeAlgorithm(newAlgo);
    const newThemeConfig = {
      ...themeConfig
    };
    newThemeConfig.algorithm = newAlgo === "dark" ? darkAlgorithm : defaultAlgorithm;
    // dispatch(setAppTheme({ key: newAlgo }));
    await dispatch(toggleAppTheme({ key: newAlgo }));
    setThemeConfig(newThemeConfig);
  }, [themeConfig, setThemeConfig, setThemeAlgorithm, dispatch]);

  useEffect(() => {
    if (!storeAppInit) {
      (async () => {
        await dispatch(initializeAppSlice());
      })();
    }
  }, [storeAppInit, updateThemeConfig, dispatch, storeAppThemeKey]);

  return (
    <ConfigProvider theme={themeConfig} locale={{ locale: "en" }} >
      <AntdApp style={{ background: "#181818FF", }}>
        <Spin style={{ minHeight: "100%", width: "100%" }} size={"large"} spinning={storeAppLoading}>
          <Outlet context={{
            themeConfig, themeAlgorithm,
            updateThemeAlgorithm: updateThemeConfig,
          }} />
          {/* <RootView themeConfig={themeConfig} themeAlgorithm={themeAlgorithm} updateThemeAlgorithm={updateThemeConfig} /> */}
        </Spin>
      </AntdApp>
    </ConfigProvider>
  );
}

export default App;
