import {
  createBrowserRouter,
  RouteObject
} from "react-router-dom";
import App from "./../App";
import RootView from "../views/RootView";


const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    errorElement: <>ERROR</>,
    children: [
      {
        index: true,
        element: <RootView />
      }
    ]
  },
];

export const router: any = createBrowserRouter(routes, {
  future: { v7_normalizeFormMethod: true }
});