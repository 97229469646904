import { memo, useEffect, useState } from "react";
import AntdLayout from "antd/lib/layout";
import { Typography, Flex } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import Menu from "antd/lib/menu"
import { ItemType, MenuItemType } from "antd/lib/menu/hooks/useItems";
import { SELF_APP_NAME } from "./../lib/app-config";
import QRCode from "./QRCode";

const { Sider } = AntdLayout;

const { Paragraph } = Typography;

interface SiderCompProps {
  collapsed: boolean;
  setCollapsed: (val: boolean) => void;
  themeAlgorithm: "dark" | "light";
  colorBgLayout: string;
  borderRadiusLG: number;
}

function SiderComp({ collapsed, setCollapsed, themeAlgorithm, colorBgLayout, borderRadiusLG, }: SiderCompProps) {

  const [init, setInit] = useState<boolean>(false);
  const [navs] = useState<ItemType<MenuItemType>[]>([
    {
      key: "home",
      icon: <HomeOutlined />,
      label: "Home",
    },
  ]);

  useEffect(() => {
    if (!init) {
      setInit(true);
    }
  }, [init])

  return (
    <Sider
      style={{
        background: colorBgLayout,
      }}
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        // console.log('broken =>', broken);
        if (!init) return;
        setCollapsed(broken);
      }}
      onCollapse={(col, type) => {
        // console.log(col, type);
        if (!init) return;
        setCollapsed(col);
      }}
      theme={themeAlgorithm}
    >
      <Flex
        style={{
          alignItems: "center"
        }}>
        <QRCode collapsed={collapsed} />
        <Paragraph style={{ margin: "auto", fontStyle: "italic", wordWrap: "break-word" }}>{SELF_APP_NAME}</Paragraph>
      </Flex>
      <Flex vertical>
        <Menu
          style={{
            margin: 5,
            borderRadius: borderRadiusLG,
          }}
          mode={"vertical"}
          defaultSelectedKeys={["home"]}
          items={navs}
        />
      </Flex>
    </Sider>
  )
}

export default memo(SiderComp);