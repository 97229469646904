import { memo, ReactNode } from "react";
import FloatButton from "antd/lib/float-button";
import Modal from "antd/lib/modal";
import Col from "antd/lib/col";
import TypographyTitle from "antd/lib/typography/Title";
import TypographyParagraph from "antd/lib/typography/Paragraph";
import { SELF_APP_HELP_INFO, SELF_APP_HELP_TITLE, SELF_APP_NAME } from "./../lib/app-config";
import { ModalFuncProps, Space } from "antd";

interface HelpFloatButtonProps {
  tooltip: ReactNode | string;
  icon: ReactNode;
  // themeConfig: ThemeConfig;
}


function ModalTitle() {
  return (
    <Col xs={{ span: 24 }} style={{ textWrap: "wrap" }}>
      <TypographyTitle level={3}>{"📚"}{SELF_APP_NAME}{"📚"}</TypographyTitle>
      <TypographyParagraph>{SELF_APP_HELP_TITLE}</TypographyParagraph>
    </Col>
  );
}

const ModalTitleMemo = memo(ModalTitle);


const modalData: ModalFuncProps = {
  title: <ModalTitleMemo />,
  content: <Space><TypographyParagraph>{SELF_APP_HELP_INFO}</TypographyParagraph></Space>,
  width: "100%",
  centered: true,
  // closable: true,
};

function HelpFloatButton({ tooltip, icon }: HelpFloatButtonProps) {

  const [modal, modalContextHolder] = Modal.useModal();

  return (
    <>
      <FloatButton tooltip={tooltip} icon={icon} onClick={() => {
        // showModal();
        modal.info(modalData);
      }} />
      {modalContextHolder}
    </>
  )
}

export default memo(HelpFloatButton);
